<template>
  <div class="content">
    <div class="bigScrean-content">
      <el-form
        ref="bigScreenForm"
        :rules="rules"
        :model="bigScreenInfo"
        label-width="100px"
      >
        <el-form-item label="群组名称" prop="name">
          <el-input v-model="bigScreenInfo.name" :disabled="true"></el-input>
        </el-form-item>

        <el-form-item label="客户Logo" prop="logoUrl">
          <form
            id="headImgfileForm1"
            class="none"
            method="post"
            enctype="multipart/form-data"
          >
            <input
              type="file"
              id="brandImageFile"
              @change="selectedImageFile1"
              name="upfile"
              accept="image/*;capture=camera"
            />
          </form>
          <div
            @click="triggerSelectFile1"
            class="order-image-wrapper pull-left"
            v-if="imageList.length < 1"
          >
            <span style="font-size:12px;text-align:center;"
              >请上传指定尺寸的logo图片<br />300 × 300</span
            >
          </div>
          <div
            @click="delImage(index)"
            :class="[
              'order-image-wrapper',
              'pull-left',
              'm-b-md',
              { 'image-m-l': (index + 1) % 3 !== 0 }
            ]"
            style="margin-right:4px"
            v-for="(image, index) in imageList"
          >
            <div
              class="image-content "
              :style="{
                'background-image': 'url(' + API_ROOT + image + ')'
              }"
              v-loading.body="loading"
            ></div>
            <div class="image-close" style="background-color: transparent">
              <i class="fa fa-times-circle" style="color:#f00;"></i>
            </div>
          </div>
        </el-form-item>

        <el-form-item label="横屏广告图" prop="imageUrl">
          <form
            id="headImgfileForm"
            class="none"
            method="post"
            enctype="multipart/form-data"
          >
            <input
              type="file"
              id="orderImageFile"
              @change="selectedImageFile"
              name="upfile"
              accept="image/*;capture=camera"
            />
          </form>
          <div class="image-content m-t-md clearfix" style="margin-top: -5px;">
            <div
              @click="triggerSelectFile"
              class="order-image-wrapper pull-left"
            >
              <span v-if="!bigScreenImageInfo.qrticket">+</span>
              <img
                v-if="bigScreenImageInfo.qrticket"
                width="80"
                height="80"
                :src="API_ROOT + bigScreenImageInfo.qrticket"
              />
              <span class="update" v-if="bigScreenImageInfo.qrticket"
                >修改</span
              >
            </div>
          </div>
        </el-form-item>

        <el-form-item label="竖屏广告图" prop="verticalImageUrl">
          <form
            id="headImgfileForm2"
            class="none"
            method="post"
            enctype="multipart/form-data"
          >
            <input
              type="file"
              id="orderImageFile2"
              @change="selectedImageFile2"
              name="upfile"
              accept="image/*;capture=camera"
            />
          </form>
          <div class="image-content m-t-md clearfix" style="margin-top: -5px;">
            <div
              @click="triggerSelectFile2"
              class="order-image-wrapper pull-left"
            >
              <span v-if="!bigScreenImageInfo.qrticket2">+</span>
              <img
                v-if="bigScreenImageInfo.qrticket2"
                width="80"
                height="80"
                :src="API_ROOT + bigScreenImageInfo.qrticket2"
              />
              <span class="update" v-if="bigScreenImageInfo.qrticket2"
                >修改</span
              >
            </div>
          </div>
        </el-form-item>

        <el-form-item label="模板名称" prop="title">
          <el-input v-model="bigScreenInfo.title"></el-input>
        </el-form-item>

        <el-form-item label="轮播时间(秒)" prop="devicePollTime">
          <el-input v-model="bigScreenInfo.devicePollTime"></el-input>
        </el-form-item>

        <el-form-item label="轮播间隔(秒)" prop="pollingTime">
          <el-input v-model="bigScreenInfo.pollingTime"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            size="large"
            @click="onSubmit(bigScreenInfo)"
            >保存</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { updateBingScreenInfo } from "../../services/screen";
import { uploadImageFile } from "../../services/common";
import { API_ROOT } from "../../config";
import { Notification } from "element-ui";
import { getFileUrl } from "../../utils/tools/tools";
export default {
  props: {
    userInfos: {
      type: Object
    }
  },
  data() {
    const valiPollingTime = (rule, value, callback) => {
      if (/(^[1-9][0-9]*$)|0/.test(value)) {
        callback();
      } else {
        callback(new Error("时间必须是数字"));
      }
      //  callback(new Error("时间必须是数字"));
    };

    return {
      API_ROOT,
      dialogImageUrl: "",
      dialogVisible: false,
      inOrOut: [
        {
          value: "indoor",
          label: "室内",
          cont: ""
        },
        {
          value: "outdoor",
          label: "室外",
          cont: ""
        }
      ],
      selectPlace: "", //存放室内还是室外位置
      indoorShowInput: false, //室内显示文本框
      outdoorShow: false, //室内显示城市
      loading: false, //加载框
      selectPermissions: [],
      allPermissions: [],
      resultList: [], //权限搜索结果
      PermissionIds: [],
      bigScreenInfo: {
        id: "", //
        userId: "", //
        title: "", // 空气道名称
        imageUrl: "", // 横屏图片
        verticalImageUrl: "", // 竖屏图片
        pollingTime: "", // 轮播间隔
        devicePollTime: "", // 轮播时间
        name: "", //客户名称
        logoUrl: "", //
        keys: null,
        macSet: null,
        remarks: "",
        /* place:'',//默认位置是室内（室内、室外）
		          indoorText:'',//室内编辑的文字
		          outdoorCity:'北京',//室外获取的城市
		          airSystem:'空气道净氧新风系统运行中',//默认室内：空⽓道净氧新⻛系统--》“空⽓道新⻛系统   */
        permissions: []
      },
      rules: {
        title: [
          { required: true, message: "请输入空气道名称", trigger: "blur" },
          { min: 2, max: 15, message: "长度在2 到 15 个字符" }
        ],
        pollingTime: [{ validator: valiPollingTime, trigger: "blur" }],
        devicePollTime: [{ validator: valiPollingTime, trigger: "blur" }]
      },
      imageList: [],
      imgUrlList: [],
      loading: false, //加载框
      bigScreenImageInfo: {
        qrticket: '',
        qrticket2: '',
      }, //存放空气道大屏的图片更改的信息  包括logo 横屏 竖屏图片
      userList: {
        userInfos: this.userInfo
      }
    };
  },

  created() {
    this.bigScreenInfo = this.userInfos; //实现自动获取该用户的数据

    if (this.userInfos.logoUrl != "") {
      this.imageList[0] = this.userInfos.logoUrl;
    } else {
      this.imageList = [];
    }

    this.bigScreenImageInfo.qrticket = this.userInfos.imageUrl;
    this.bigScreenImageInfo.qrticket2 = this.userInfos.verticalImageUrl;
    this.bigScreenInfo = Object.assign({}, this.userInfos);
    //			console.log(this.bigScreenInfo)
  },
  methods: {
    getSrcList(val) {
      this.bigScreenInfo = val;
    },
    /*			isSelectOut(selVal){
				this.selectPlace = selVal;
				if(this.selectPlace=='indoor'){
					this.bigScreenInfo.outdoorCity='';
					this.indoorShowInput=true;
					this.outdoorShow=false;
					this.bigScreenInfo.airSystem="空气道净氧新风系统运行中";

				}else{
					this.bigScreenInfo.indoorText='';
					this.bigScreenInfo.outdoorCity='北京';//此处是动态获取城市
					this.outdoorShow=true;
					this.indoorShowInput=false;
					this.bigScreenInfo.airSystem="空气道新风系统运行中";
				}

			},*/

    /**
     * 触发文件选择
     */
    triggerSelectFile() {
      //横屏
      document.querySelector("#orderImageFile").click();
    },
    triggerSelectFile2() {
      //竖屏
      document.querySelector("#orderImageFile2").click();
    },
    triggerSelectFile1() {
      //logo
      document.querySelector("#brandImageFile").click();
    },
    /**
     * LOGO图片的选中
     */
    selectedImageFile1($event) {
      let files = $event.target.files;
      let file = null;
      if (files && files.length) {
        file = files[0];
      }

      if (file && /^image\/\w+/.test(file.type)) {
        if (file.size / 1024 / 1024 / 10 > 1) {
          //10M
          Notification.error({
            title: "错误",
            message: "请选择一个小于10M图片"
          });
          return;
        }
      } else {
        Notification.error({
          title: "错误",
          message: "请选择一个图片文件"
        });
        return;
      }
      //获取 URL.createObjectURL 地址
      const fileImageUrl = getFileUrl("#brandImageFile");
      let image = new Image();
      image.src = fileImageUrl;
      this.loading = true;
      image.onload = () => {
        this.imageList.push({ fileImage: fileImageUrl });
        this.uploadImageFile(file, "two");
      };
      image.onerror = () => {
        Notification.error({
          title: "错误",
          message: "请选择一个正确的图片文件"
        });
      };
    },

    /**
     * 横屏图片的选中
     */
    selectedImageFile($event) {
      let files = $event.target.files;
      let file = null;
      if (files && files.length) {
        file = files[0];
      }

      if (file && /^image\/\w+/.test(file.type)) {
        if (file.size / 1024 / 1024 / 10 > 1) {
          //10M
          Notification.error({
            title: "错误",
            message: "请选择一个小于10M图片"
          });
          return;
        }
      } else {
        Notification.error({
          title: "错误",
          message: "请选择一个图片文件"
        });
        return;
      }

      //获取 URL.createObjectURL 地址
      const fileImageUrl = getFileUrl("#orderImageFile");
      let image = new Image();
      image.src = fileImageUrl;
      this.loading = true;
      image.onload = () => {
        this.uploadImageFile(file, "one");
      };
      image.onerror = () => {
        Notification.error({
          title: "错误",
          message: "请选择一个正确的图片文件"
        });
      };
    },
    /**
     * 竖屏图片的选中
     */
    selectedImageFile2($event) {
      let files = $event.target.files;
      let file = null;
      if (files && files.length) {
        file = files[0];
      }

      if (file && /^image\/\w+/.test(file.type)) {
        if (file.size / 1024 / 1024 / 10 > 1) {
          //10M
          Notification.error({
            title: "错误",
            message: "请选择一个小于10M图片"
          });
          return;
        }
      } else {
        Notification.error({
          title: "错误",
          message: "请选择一个图片文件"
        });
        return;
      }

      //获取 URL.createObjectURL 地址
      const fileImageUrl = getFileUrl("#orderImageFile2");
      let image = new Image();
      image.src = fileImageUrl;
      this.loading = true;
      image.onload = () => {
        this.uploadImageFile(file, "three");
      };
      image.onerror = () => {
        Notification.error({
          title: "错误",
          message: "请选择一个正确的图片文件"
        });
      };
    },

    /**
     * @param index 在imageList中的下标  删除图片
     */
    delImage(index) {
      this.imageList.splice(index, 1);
    },
    /**
     * 上传图片
     * @param imageFile Blob file
     */
    async uploadImageFile(imageFile, tag) {
      const aafile = new File([imageFile], "cnm.png"); //修改文件名
      //      console.log(aafile)
      const uploadData = await uploadImageFile(aafile);
      if (
        uploadData &&
        uploadData.errorCode === 0 &&
        uploadData.data &&
        uploadData.data.result
      ) {
        
        
        if (tag == "one") {
          this.bigScreenImageInfo.qrticket = uploadData.data.fpicurl;
          console.warn(this.bigScreenImageInfo.qrticket );
        } else if (tag == "three") {
          this.bigScreenImageInfo.qrticket2 = uploadData.data.fpicurl;
        } else {
          this.imageList[this.imageList.length - 1] = uploadData.data.fpicurl;
        }
        this.loading = false;
        document.getElementById("headImgfileForm").reset();
        document.getElementById("headImgfileForm2").reset();
        document.getElementById("headImgfileForm1").reset();
      }
    },

    onSubmit(updateInfo) {
      this.$refs.bigScreenForm.validate(valid => {
        if (valid) {
          //提交保存按钮获得的值
          this.imgUrlList = [];
          this.imageList.forEach(item => {
            this.imgUrlList.push(item);
          });
          let logoUrl = this.imgUrlList.join("");

          updateInfo.logoUrl = logoUrl;
          updateInfo.imageUrl = this.bigScreenImageInfo.qrticket; //横屏
          updateInfo.verticalImageUrl = this.bigScreenImageInfo.qrticket2; //竖屏
          this.$emit("onSubmitUpdata", updateInfo);
        }
      });
    }
  },

  watch: {
    userInfos: function(value) {
      this.userList["userInfos"] = value;

      this.$nextTick(() => {
        this.bigScreenInfo = this.userInfos; //实现自动获取该用户的数据
        if (this.userInfos.logoUrl != "") {
          this.imageList[0] = this.userInfos.logoUrl;
        } else {
          this.imageList = [];
        }
        this.bigScreenImageInfo.qrticket = this.userInfos.imageUrl;
        this.bigScreenImageInfo.qrticket2 = this.userInfos.verticalImageUrl;
      });
    }
  }
};
</script>

<style lang="less" scoped>
@import "../../assets/css/variables";

.bigScrean-content {
  margin: 0 auto;
  width: 500px;

  .el-upload--picture-card {
    width: 74px;
    height: 74px;
    line-height: 74px;
  }
  #in-out {
    width: 100%;
    height: 50px;
    .out-show {
      width: 100%;
      height: 100%;
      line-height: 50px;
    }
  }
}

.order-image-wrapper {
  overflow: hidden;
  position: relative;
  height: 120px;
  width: 120px;
  line-height: 120px;
  border: 1px solid #bfcbd9;
  border-radius: 4px;
  font-size: 100px;
  // line-height: 126px;
  text-align: center;
  color: #ccc;
  cursor: pointer;
  span {
    position: relative;
    bottom: 10px;
    display: inline-block;
    line-height: 16px;
    width: 100%;
    word-wrap: break-word;
  }
  .image-content {
    position: relative;
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
  .image-close {
    position: absolute;
    right: 0;
    top: 0;
    height: 20px;
    width: 20px;
    text-align: center;
    line-height: 20px;
    background-color: #1c2b36;
    color: #fff;
    font-size: @font-size-base;
  }
  .update {
    position: absolute;
    transform: rotate(45deg);
    top: 1px;
    right: -30px;
    width: 80px;
    height: 20px;
    line-height: 20px;
    font-size: 2px !important;
    color: #f00;
    background-color: #eee;
  }
}
</style>
