<template>
   <div class="content">
	<div class="bigScrean-content">
      <el-form ref="bigScreenForm" :rules="rules" :model="bigScreenInfo" label-width="80px">
      	<el-row :gutter="24">
      		<el-col :span="24">
		        <el-form-item label="模板名称" prop="title">
		          <el-input v-model="bigScreenInfo.title" placeholder="请输入模板名称..."></el-input>
		        </el-form-item>
        	</el-col>
        </el-row>
        <el-row :gutter="24">
        	<el-col :span="24">
		        <!--选用的是微信用户的昵称-->
		         <el-form-item label="群组名称" prop="userName">
			          <el-select v-model="bigScreenInfo.userName" placeholder="请选择客户..."
                  filterable
                  remote
			          	style="width: 100%;"
                  :remote-method="getAllUsersName"
			          	@change="onChange"
			          	value-key="id">
			              <el-option
			                v-for="(item,index) in allUserName"
			                :key="index"
			                :label="item.name"
			                :value="item"
			                :data-mobile="item.mobile?item.mobile:'null'"
			                >
			              </el-option>
			          </el-select>
			     </el-form-item>
        	</el-col>
        </el-row>
        <el-row :gutter="24">
        	<el-col :span="24">
		        <el-form-item label="备	注" prop="remarks">
		          <el-input v-model="bigScreenInfo.remarks" placeholder="请输入备注..."></el-input>
		        </el-form-item>
        	</el-col>
        </el-row>
        <el-form-item class="text-right m-t-md">
		          <el-button type="primary" size="large" @click="onSubmit(bigScreenInfo)">保存</el-button>
		</el-form-item>
      </el-form>
    </div>
  </div>


</template>

<script>
	import {createBigScreen} from '../../services/screen';
	import {getBigUser, removeUser, disabledUser, enabledUser, resetUserPwd} from '../../services/user';//获得用户信息
	import {valiSelect} from '../../utils/validator/element'
	export default {
		data(){
			const valiName = (rule, value, callback) => {
	        if (value === '') {
	          callback(new Error('请输入客户名称'));
	        } else {
	          callback();
	        }
	      };
	       return {
		        loading: false, //加载框
		        selectPermissions: [],
		        allPermissions: [],
		        resultList: [],//权限搜索结果
		        PermissionIds: [],
		        allUserName:[],//
		        wechatUsers:[],//筛选出所有微信用户
		        userInfo: {},
		        bigScreenInfo: {
		          id: '',
		          title:'',//模板名称
		          userName:'',//客户名称
		          userId: '',//所选客户id
		          mobile:'',//手机
		          remarks:'',//备注
		          logoUrl: '',//logo图片地址
		          imageUrl: '',//广告图片地址
		          createTime: '',
		          updateTime: '',
		          code: '',//代码状态
		          permissions: [],//权限
		          pollingTime: 0,
				  keys: "",
				  selectedUserInfo:[]//所选客户所带的信息
		        },
		        rules: {
		           title: [//账户
		            { required: true, message: '请输入模板名称', trigger: 'blur' },
                    { min: 2, max: 15, message: '长度在2 到 15 个字符'}
		          ],
		          userId: [//客户
		            {validator: valiName, trigger: 'change'}
		          ]
		        }
      		}

		},
		created(){
			this.getAllUsersName()
			this.bigScreenInfo= {
		          id: '',
		          title:'',//模板名称
		          userName:'',//客户名称
		          userId: '',//所选客户id
		          mobile:'',//手机
		          remarks:'',//备注
		          logoUrl: '',//logo图片地址
		          imageUrl: '',//广告图片地址
		          createTime: '',
		          updateTime: '',
		          code: '',//代码状态
		          permissions: [],//权限
		          pollingTime: 0,
				  keys: "",
				  selectedUserInfo:[]//所选客户所带的信息
		        }
		},
		methods:{
			/**
	       * 获取所有的微信客户
	       */
		      async getAllUsersName(name='') {
		        const userNameData = await getBigUser({name});
		        if (userNameData && userNameData.data &&userNameData.errorCode === 0) {
		        	const dataList = userNameData.data.datas || [];
              // this.allUserName=dataList.filter(item => item.source === 'wechat');
              this.allUserName = dataList;
              
		        }
		      },

		      onChange(item) {
		      	this.bigScreenInfo.userId = item.id;
		      	this.bigScreenInfo.mobile=item.mobile;
//		      		console.log(this.bigScreenInfo);
		      },

		      /*
		            新建
		       * */
			 async addBigScreen(userId) {
		        const addData = await createBigScreen(userId);
//		        console.log(addData)
		         this.$emit('onSubmit');
		      },

			onSubmit(addInfo){
//				console.log(addInfo)
				this.$refs.bigScreenForm.validate((valid) => {

					this.wechatUsers.map((resut)=>{
						if(resut.id==this.bigScreenInfo.userId){
							//将该客户的相关信息存入
							this.bigScreenInfo.selectedUserInfo.push(resut);
							this.bigScreenInfo.userName=resut.name//客户昵称
						}
					})
		          if (valid) {
					if(this.bigScreenInfo.userName!=''){
							let	inData={
							id:this.bigScreenInfo.id,
							userId:this.bigScreenInfo.userId,
							userName:this.bigScreenInfo.userName,
	    					logoUrl:this.bigScreenInfo.logoUrl,
	    					title: this.bigScreenInfo.title,
	    					imageUrl: this.bigScreenInfo.imageUrl,
	    					mobile:this.bigScreenInfo.mobile
						}
	                       this.addBigScreen(inData);
					}else{
						 this.$message.error('请选择客户昵称');
						return;
					}
//		          	console.log(this.bigScreenInfo);
		          }
		        });

			}
		},





	}


</script>

<style lang="less" scoped>
  .bigScrean-content {
    margin: 0 auto;
    width: 500px;
  }
</style>
