<style lang="less" scoped>
  .content {
    margin-bottom: 30px !important;
    .list-content {
      margin-bottom: 50px;
      padding: 30px;
      .toolbar {
        padding-top: 20px;
      }
    }
  }
  #setDeviceShow .el-checkbox {
    margin: 10px 15px 15px 0;
  }
  #setDeviceShow .el-checkbox + .el-checkbox {
    margin: 10px 15px 15px 0;
  }
</style>
<template>
  <div class="page-wrapper">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/user/list' }">
        大屏管理
      </el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/user/list' }">
        管理列表
      </el-breadcrumb-item>
    </el-breadcrumb>
    <div class="page-container clearfix" v-on:keyup.13="getDataByTitle">
      <!--查询筛选-->
      <el-col :span="24" style="padding-bottom: 0px;">
        <el-form :inline="true" :model="filters">
          <el-form-item>
            <el-input
              style="width: 240px;"
              v-model="filters.title"
              placeholder="根据模板名称筛选"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="getDataByTitle">查询</el-button>
          </el-form-item>
        </el-form>
      </el-col>
      <el-table
        :data="filterList.length > 0 ? filterList : bigScreenList"
        @selection-change="selsChange"
        class="list-table "
      >
        <el-table-column
          :selectable="
            (row) => {
              return !row.system;
            }
          "
          type="selection"
          width="35"
        ></el-table-column>
        <el-table-column align="center" label="序号" min-width="70">
          <template slot-scope="scope">
            <span>{{ scope.$index + pager.size * (pager.page - 1) + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="title"
          label="显示模板名称"
          min-width="160"
          sortable
        ></el-table-column>
        <el-table-column
          prop="name"
          label="群组名称"
          min-width="160"
        ></el-table-column>
        <el-table-column
          prop="userName"
          label="群组账号"
          class-name="el-column-ellipsis"
          min-width="160"
        ></el-table-column>
        <el-table-column
          prop="mobile"
          label="联系方式"
          class-name="el-column-ellipsis"
          min-width="160"
        ></el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          min-width="160"
          label="创建时间"
        >
          <template slot-scope="scope">
            {{ scope.row.createTime | formatTime }}
          </template>
        </el-table-column>

        <el-table-column
          label="操作"
          width="200"
          v-if="user.role === 'admin' || user.role === 'bigScrean'"
        >
          <template slot-scope="scope">
            <el-button size="small" @click="setDeviceBtn(scope.row)">
              设备
            </el-button>
            <el-button
              size="small"
              @click="handleEdit(scope.$index, scope.row)"
            >
              配置
            </el-button>
            <el-button
              type="danger"
              class="m-l-xs"
              size="small"
              @click="handleDel(scope.$index, scope.row)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-col :span="24" class="bottom-tool-container">
        <el-button
          v-if="user.role === 'admin' || user.role === 'bigScrean'"
          type="primary"
          @click="addBigScreen"
        >
          新建模板
        </el-button>
        <!--<el-button v-if="user.role ==='admin'" type="danger" @click="batchRemove" :disabled="this.sels.length===0">批量删除</el-button>-->
        <!--分页-->
        <el-pagination
          @size-change="pageSizeChange"
          @current-change="pageCurrentChange"
          :current-page="pager.page"
          :page-sizes="[PAGE_SIZE, PAGE_SIZE * 2, PAGE_SIZE * 3, 100]"
          :page-size="pager.size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          background
          class="pull-right"
        ></el-pagination>
      </el-col>
    </div>

    <!--新增模板-->
    <el-dialog
      :close-on-click-modal="false"
      title="新建模板"
      size="small"
      :visible.sync="addDetailVisible"
    >
      <add-view
        v-if="isShow"
        :defaultRoleInfo="choosedUser"
        :addDetailVisible="addDetailVisible"
        :isEdit="isEdit"
        :defaultPmtIds="permissionIds"
        v-on:onSubmit="addDetailSubmit"
      ></add-view>
    </el-dialog>

    <!--编辑配置大屏-->
    <el-dialog
      :close-on-click-modal="false"
      title="配置模板"
      size="small"
      :visible.sync="updateDetailVisible"
    >
      <update-view
        :defaultRoleInfo="choosedUser"
        :updateDetailVisible="updateDetailVisible"
        :isEdit="isEdit"
        :defaultPmtIds="permissionIds"
        v-on:onSubmitUpdata="updateDetailSubmit"
        :userInfos="userInfos"
      ></update-view>
    </el-dialog>

    <!--修改设备的显示情况-->
    <el-dialog
      title="请选择需要显示的设备"
      ref="checkDevice"
      :visible.sync="deviceDialogVisible"
      width="60%"
      center
    >
      <template>
        <el-checkbox-group v-model="userToShowDdeviceList" id="setDeviceShow">
          <span v-if="userAllDeviceList.length == 0">
            抱歉，该用户未绑定任何设备！
          </span>
          <el-checkbox
            v-for="item in userAllDeviceList"
            :label="item.id"
            :key="item.id"
          >
            {{ item.name }}
          </el-checkbox>
        </el-checkbox-group>
      </template>

      <span slot="footer" class="dialog-footer">
        <el-button @click="deviceDialogVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click="updateDeviceSubmit(userToShowDdeviceList)"
        >
          确 定
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
  import {
    getAllScreenList,
    getUserById,
    getDataById,
    removeBigScreen,
    getBigScreenPermission,
    getUserDeviceById,
    uploadDeviceStatus,
    updateBingScreenInfo,
  } from "../../services/screen";
  import { Notification } from "element-ui";
  import { PAGE_SIZE } from "../../config";
  import updateBigScreenView from "./updateBigScreen.vue"; //修改组件
  import addBigScreenView from "./addBigScreen.vue"; //新增组件
  import { mapGetters } from "vuex";
  import moment from "moment";
  import { searchUser } from "../../services/user"; //获得用户信息
  export default {
    data() {
      return {
        isShow: false,
        editPermission: true,
        isEdit: false,
        fetchbigScreenList: [],
        filterBigScreenList: [],
        bigScreenList: [],
        filterList: [],
        filters: {
          name: "",
          title: "", //模板名称
          source: "",
          key: "",
        },
        PAGE_SIZE,
        pager: {
          page: 1,
          size: PAGE_SIZE,
        },
        total: 0,
        listLoading: false,
        addDetailVisible: false, //新增
        updateDetailVisible: false, //修改
        deviceDialogVisible: false, //设备显示情况
        choosedRowIndex: 0,
        setShow: {},
        choosedUser: {
          //选择的用户数据
          id: "",
          name: "",
          code: "",
          remarks: "",
          bigScreenList: [],
        },
        sels: [], //列表选中列
        permissionIds: [],
        userAllDeviceList: [], //查询该用户对应的所有设备
        userToShowDdeviceList: [], //查询该用户已经设置要显示的设备
        deviceIds: [], //查询该用户的设备id数组
        userId: "",
        userInfos: {},
        wechatUsers: [], //微信用户
        updataInfo: {
          //用来专门存放修改后的数据，作为数据存传回后台做修改
          id: "", //
          userId: "", //
          userName: "", //客户名称
          title: "", // 空气道名称
          logoUrl: "", //logo图片
          imageUrl: "", // 横屏图片
          verticalImageUrl: "", // 竖屏图片
          pollingTime: "", // 轮播时间
        },
      };
    },
    computed: {
      ...mapGetters({ user: "userInfo" }),
    },
    components: {
      "update-view": updateBigScreenView,
      "add-view": addBigScreenView,
    },
    filters: {
      formatTime(row) {
        return row ? moment(row).format("YYYY-MM-DD") : "";
      },
    },
    methods: {
      /*
    	 配置设备的弹框按钮
    	 * */
      async setDeviceBtn(deviceInfo) {
        this.deviceDialogVisible = true;
        //  		console.log("配置设备"+JSON.stringify(deviceInfo));
        this.userId = deviceInfo.userId;
        const userDeviceData = await getUserDeviceById(this.userId);
        if (
          userDeviceData &&
          userDeviceData.data &&
          userDeviceData.errorCode === 0
        ) {
          this.userAllDeviceList = userDeviceData.data.deviceList;
        }
        //  		 console.log(this.userAllDeviceList)

        //每次进入都更新一遍
        var ToShowDdeviceList = [];
        var deviceIds = [];

        //若show为true，就会在复选框里自动勾选
        for (let i = 0; i < this.userAllDeviceList.length; i++) {
          if (this.userAllDeviceList[i].show) {
            ToShowDdeviceList.push(this.userAllDeviceList[i].id);
            deviceIds.push(this.userAllDeviceList[i].id);
            this.userToShowDdeviceList = ToShowDdeviceList;
            this.deviceIds = deviceIds;
          } else {
            this.userToShowDdeviceList = ToShowDdeviceList;
          }
        }
        //  		 console.log(this.userToShowDdeviceList)
      },
      /*
    	 确定修改设备显示
    	 * */
      async updateDeviceSubmit(deviceIds) {
        this.userToShowDdeviceList = deviceIds;
        //  		console.log("设备id: "+deviceIds)
        //				console.log("用户id:"+this.userId)
        //				console.log(this.userToShowDdeviceList)
        let ids = {
          userId: this.userId,
          deviceIds: deviceIds.join(","),
        };
        const deviceStatus = await uploadDeviceStatus(ids);
        this.deviceDialogVisible = false;
      },
      /**
       * 判断角色修改权限
       */
      checkEditPermission() {
        let permissions = this.user && this.user.permissions;
        this.editPermission = permissions.some((item) => {
          return item === "Role:update";
        });
      },
      /**
       * 格式化描述
       */
      formatDesc(row, column) {
        return row.desc && row.desc.length > 45
          ? row.desc.substr(0, 45).padEnd(3, "...")
          : row.desc;
      },
      /**
       * 根据权限名称筛选，这是一个本地的filter
       */
      getDataByTitle() {
        this.filterList = [];
        for (let i = 0; i < this.bigScreenList.length; i++) {
          if (this.bigScreenList[i].title == this.filters.title) {
            //  			console.log(this.bigScreenList[i])
            this.filterList.push(this.bigScreenList[i]);
            return;
          }
        }
        this.$message.error("抱歉，你要查询的模板不存在，请确保输入是否正确！");
      },
      /**
       * 单条数据的删除
       */
      handleDel: function(index, row) {
        this.$confirm("确认删除该记录吗?", "提示", {
          type: "warning",
        })
          .then(() => {
            this.listLoading = true;
            removeBigScreen(row.id).then((res) => {
              this.listLoading = false;
              this.fetchbigScreenList.splice(
                this.pager.size * this.pager.page + index,
                1
              );
              this.refreshbigScreenList();
              Notification.success({
                title: "成功",
                message: "删除成功",
              });
              this.getAllScreenList();
            });
          })
          .catch(() => {});
      },

      /**
       * 删除，新增、修改后重新刷新目录list
       * @returns {Promise.<void>}
       */
      refreshbigScreenList() {
        this.filterBigScreenList = this.fetchbigScreenList.filter((item) => {
          return item.name.includes(this.filters.name);
        });
        this.bigScreenList = this.filterBigScreenList.slice(
          this.pager.page * this.pager.size,
          (this.pager.page + 1) * this.pager.size
        );
      },
      /**
       * 点击新增初始化
       */
      addBigScreen() {
        this.isShow = true;
        //    	console.log("点击了新增按钮")
        this.addDetailVisible = true;
        this.permissionIds = [];
      },

      /**
       * checkbox勾选的变动的回调
       */
      selsChange: function(sels) {
        this.sels = sels;
      },

      /**
       * 设置分页的size
       */
      pageSizeChange(val) {
        //    	 console.log(`每页 ${val} 条`);
        this.pager.size = val;
        this.pager.page = 1;
        this.getAllScreenList();
      },
      /**
       *  翻页
       */
      pageCurrentChange(page) {
        //    	console.log(`当前页: ${page}`);
        //        this.pager.page = page;
        //        this.getAllScreenList();
        if (this.loaded) {
          this.pager.page = page;
          window.router.replace({ path: "list", query: { page: page } });
          this.getAllScreenList();
        }
      },
      /**
       * 获取所有的账号
       */
      async getAllScreenList() {
        this.listLoading = true;
        if (this.filters.source == "") {
          this.filters.source == null;
        }
        const responseData = await getAllScreenList("", this.pager);
        if (responseData && responseData.data && responseData.errorCode === 0) {
          this.bigScreenList = responseData.data.datas || [];
          this.total = responseData.data.totalCount;
        }
        this.loaded = true;
        this.listLoading = false;
      },
      /**
       *用户修改或者新增成功
       * @param isCreate true 创建  false 修改
       */
      addDetailSubmit(roleData) {
        const roleInfo = Object.assign({}, roleData);
        //      console.log("新增完成")
        if (this.isEdit) {
          this.bigScreenList.splice(this.choosedRowIndex, 1, roleInfo);
        } else {
          this.total++;
          this.bigScreenList.push(roleInfo);
          if (this.pager.page === Math.floor(this.total / this.pager.size)) {
            if (this.total % PAGE_SIZE === 1) {
              this.pager.page++;
            }
          }

          //        console.log(this.pager.page)
        }
        this.addDetailVisible = false;
        this.getAllScreenList();
        this.isShow = false;
      },
      /**
       * 点击按钮跳转到配置编辑
       */
      async handleEdit(index, row) {
        this.choosedRowIndex = index;
        this.isEdit = true;
        this.permissionIds = [];
        this.choosedUser = Object.assign({}, row);
        const roletemp = await getBigScreenPermission(this.choosedUser.id);
        this.userInfos = roletemp;
        //      console.log("点击配置获得该用户绑定的信息："+JSON.stringify(roletemp))

        this.updataInfo.id = roletemp.id; //传id
        this.updataInfo.userId = roletemp.userId; //传用户id

        this.updateDetailVisible = true;
      },
      async updateDetailSubmit(updateInfo) {
        //修改
        this.updataInfo.title = updateInfo.title;
        this.updataInfo.logoUrl = updateInfo.logoUrl;
        this.updataInfo.imageUrl = updateInfo.imageUrl;
        this.updataInfo.verticalImageUrl = updateInfo.verticalImageUrl;
        this.updataInfo.pollingTime = Number(updateInfo.pollingTime);
        this.updataInfo.devicePollTime = Number(updateInfo.devicePollTime);
        //				 console.log("修改后传回来的完整值："+JSON.stringify(this.updataInfo))

        //返回后台
        const updataData = await updateBingScreenInfo(this.updataInfo);
        if (updataData.errorCode === 0) {
          this.getAllScreenList();
          this.$store.dispatch("refreshRoles", this.bigScreenList);
          Notification.success({
            title: "成功",
            message: "操作成功",
          });
        }
        this.updateDetailVisible = false;
      },
    },
    created() {
      if (this.$route.query.page) {
        this.pager.page = +this.$route.query.page;
      }

      this.getAllScreenList();
      this.checkEditPermission();
    },
    watch: {
      updateDetailVisible(newVal) {
        if (newVal === false) {
          this.isEdit = false;
          this.choosedUser = {
            id: "",
            name: "",
            code: "",
            desc: "",
            bigScreenList: [],
          };
        }
      },
      addDetailVisible(newVal) {
        if (newVal === false) {
          this.isEdit = false;
          this.choosedUser = {
            id: "",
            name: "",
            code: "",
            desc: "",
            bigScreenList: [],
          };
        }
      },
    },
  };
</script>
