/**
 * Created by zhongxiaoyou on 18/9/12.
 *
 * 大屏的service封装
 *
 */
import * as API from "../utils/http/api";
import { PAGE_SIZE } from "../config";

/**
 * 获取所有的屏幕列表
 * @returns {Promise.<*>}
 */
export async function getAllScreenList(
  key = { key: "" },
  pager = { size: PAGE_SIZE, page: 1 }
) {
  try {
    return await API.get("templeteUser", { ...key, ...pager });
  } catch (error) {
    return error;
  }
}

//根据用户id查询到对应用户
export async function getUserById() {
  try {
    return await API.get("templeteUser/byUserId");
  } catch (error) {
    return error;
  }
}

//根据id查询数据
export async function getDataById(id) {
  try {
    return await API.get("/templeteUser", id);
  } catch (error) {
    return error;
  }
}

/**
 * 获取所有的权限
 * @returns {Promise.<*>}
 */
export async function getAllPermissions() {
  try {
    return await API.get("permissions", { size: 999, sort: "entity" });
  } catch (error) {
    return error;
  }
}

/**
 * 新增大屏模板
 * @param role Object {name:'',code:'',desc:''}
 * @returns {Promise.<*>}
 */
export async function createBigScreen(userInfo) {
  try {
    return await API.post("templeteUser", userInfo);
  } catch (error) {
    return error;
  }
}

/**
 * (修改)配置大屏管理模板
 * @param role Object {id:'',userId:'',logoUrl:'',title:'',imageUrl:'',pollingTime:''}
 * @returns {Promise.<*>}
 */
export async function updateBingScreenInfo(updateInfo) {
  try {
    return await API.post("templeteUser/update", updateInfo);
  } catch (error) {
    return error;
  }
}

/**
 * 创建大屏的权限
 * @param perms Array [{id:rolePermissionId}]
 * @returns {Promise.<*>}
 */
export async function createRolePermissions(perms) {
  try {
    return await API.post("rolePermissions/mutil/add", {
      rolePermissionsLists: perms,
    });
  } catch (error) {
    return error;
  }
}

/**
 * 修改大屏的权限 ,本项目中，修改和新增都用这个
 * @param bigScreenId String 大屏ID
 * @param permissionIds  String 权限的ids 用逗号隔开如2,3,4
 * @returns {Promise.<*>}
 */
export async function updateBigScreenPermissions(roleId, permissionIds) {
  try {
    return await API.post("rolePermissions/mutil/update/" + roleId, {
      permissionIds,
    });
  } catch (error) {
    return error;
  }
}

/**
 * 删除该大屏
 * @param ids String
 * @returns {Promise.<*>}
 */
export async function removeBigScreen(ids) {
  try {
    return await API.del("templeteUser/" + ids);
  } catch (error) {
    return error;
  }
}

/*
 *获取用户所绑定的所有设备
 *
 */
export async function getUserDeviceById(userId) {
  try {
    return await API.get("templeteUser/findDeviceByUserId", { userId: userId });
  } catch (error) {
    return error;
  }
}

/*
 *修改用户所绑定的设备状态
 *
 */
export async function uploadDeviceStatus(ids) {
  try {
    return await API.get("templeteUser/uploadDevice", ids);
  } catch (error) {
    return error;
  }
}

/**
 * 获取大屏列表信息
 * @param id String 角色ID
 * @returns {Promise.<*>}
 */
export async function getBigScreenById(id) {
  try {
    return await API.get("roles/detail/" + id);
  } catch (error) {
    return error;
  }
}

/**
 * 获取大屏的权限
 * @param id String 角色ID
 * @returns {Promise.<*>}
 */

export async function getBigScreenPermission(id) {
  try {
    const permissions = await API.get("templeteUser/" + id);
    return permissions.data;
  } catch (error) {
    return error;
  }
}
